import React from "react";
import styled from "styled-components";

const Title = styled.h2`
  font-family: Helvetica, sans-serif;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.color};
`;

const SectionTitle = ({text, color}) => {
    return <Title color={color}>{text}</Title>
};

export default SectionTitle;