const colors = {
  white: '#FFFFFF',
  black: '#000000',
  blue: '#0073b7',
  grey: '#c3c7ca',
  lightBlue: '#F7FAFC',
  background: '#fcfcfc',
  foreground: '#3a3169',
  accent1: '#6f4268',
  accent2: '#a45467',
  accent3: '#d96666'
};

export default colors;
