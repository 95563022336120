import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"
import { GridThemeProvider } from "styled-bootstrap-grid"
import GlobalStyle from "../../styles/global"
import Footer from "./Footer"
import Header from "./Header"

const GlobalContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const PageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-top: -76px;
`

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
  },
  row: {
    padding: 0
  },
  col: {
    padding: 0
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540
    }
  }
}

const styledTheme = {}


const Layout = ({ isIndex, children }) => (
  <React.Fragment>
    <ThemeProvider theme={styledTheme}>
      <GridThemeProvider gridTheme={gridTheme}>
        <GlobalStyle/>
        <GlobalContainer>
          {isIndex === false && <Header/>}
          <PageContainer>
            {children}
          </PageContainer>
          <Footer/>
        </GlobalContainer>
      </GridThemeProvider>
    </ThemeProvider>
  </React.Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
