import { createGlobalStyle } from "styled-components"
import { reset } from "styled-reset"

const GlobalStyle = createGlobalStyle`
    ${reset}
    
    body {
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
`;

export default GlobalStyle;
