import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

const MobileNavContainer = styled.div`
  display: ${props => props.isOpen ? "block" : "none"};
  padding: 35px 32px;
  background-position: bottom right;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-size: 262.83px 296px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HomeLink = styled(Link)`
`;

const Logo = styled.img`
`;

const CloseButton = styled.div`
`;

const Close = styled.img`
`;

const Nav = styled.div`
  margin-top: 124px;
`;

const NavItem = styled.div`
  margin-bottom: 2px;
`;

const NavLink = styled(Link)`
  text-transform: uppercase;
  font-size: 36px;
  line-height: 59px;
  text-decoration: none;
  
  &:hover,
  &.active {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: 1px;
  }
`;

const MobileNav = ({isOpen, toggleNav, closeIfActive}) => (
  <MobileNavContainer isOpen={isOpen === true}>
    <Header>
      <HomeLink to={'/'}>
        taxyy
      </HomeLink>
      <CloseButton onClick={toggleNav}>
        X
      </CloseButton>
    </Header>
    <Nav>
      <NavItem>
        <NavLink to={'/what-we-do'} activeClassName={"active"} onClick={closeIfActive}>
          What We Do
        </NavLink>
      </NavItem>
    </Nav>
  </MobileNavContainer>
);

export default MobileNav;
