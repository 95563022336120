import React, {useState} from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import MobileNav from "./MobileNav"
import menuIcon from '../../resources/images/ui/menu.svg'
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { Container } from "styled-bootstrap-grid"


const HeaderContainer = styled(Container)`
  position: relative;
  display: block;
  z-index: 50;
  @media (min-width: ${breakpoints.md}) {
  }
`

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const MobileNavContainer = styled.div`
  display: flex;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Nav = styled.div`
  display: none;
  @media(min-width: ${breakpoints.md}){
      display: block;
  }
`

const NavItem = styled.div`
  display: inline-block;
  padding: 30px 0;
  &:first-child{
    margin-right: 50px;
  }
`

const NavLink = styled(Link)`
  font-size: 12px;
  letter-spacing: 2px;
  padding-bottom: 8px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  color: ${colors.white};
  font-family: Helvetica, sans-serif;
  transition: .2s;
  &:hover {
      border-bottom: 2px solid ${colors.white};
      transition: .2s;
  }
  @media(min-width: ${breakpoints.md}){
      font-size: 16px;
  }
`

const BurgerMenu = styled.div`

`

const Burger = styled.img`
  border: 0;
  outline: none;
  box-shadow: none;
  width: 36px;
  height: 15px;
  cursor: pointer;
  display: ${props => props.show ? 'block' : 'none'};
`

const Header = () => {
  const [isNavOpen, setNavOpen] = useState(true);

  const closeIfActive = (e) => {
    if (e.currentTarget.className.includes("active")) {
      setNavOpen(false)
    }
  }

  return (
    <HeaderContainer>
      <HeaderBlock>
        <Nav>
          <NavItem>
            <NavLink to={"/privacy"} activeClassName={"active"}>
              Privacy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to={"/resources"} activeClassName={"active"}>
              Resources
            </NavLink>
          </NavItem>
        </Nav>

        <MobileNavContainer>
          <BurgerMenu>
            <Burger src={menuIcon} alt={"Mobile Nav"} onClick={() => setNavOpen(!isNavOpen)}/>
          </BurgerMenu>
          <MobileNav toggleNav={() => setNavOpen(!isNavOpen)} isOpen={isNavOpen} closeIfActive={closeIfActive.bind(this)}/>
        </MobileNavContainer>
        <NavItem>
          <NavLink to={"/sign-in"} activeClassName={"active"}>
            Sign in
          </NavLink>
        </NavItem>
      </HeaderBlock>
    </HeaderContainer>
  )
};

export default Header;