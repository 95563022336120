import React from "react"
import styled, { keyframes } from "styled-components"
import { Col, Container, Row } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import LogoWhiteImg from "../../resources/images/logo-white.svg"
import FacebookImg from "../../resources/images/facebook.svg"
import breakpoints from "../../styles/breakpoints"

const IrieLogo = styled.img`
  height: 50px;
`

const FooterBlock = styled.div`
  background-color: #2a2444;
  padding-top: 4em;
  padding-bottom: 3em;
  line-height: 1.4;
  font-size: 15px;
  color: #eaeaea;
  font-family: "Droid Sans", sans-serif;
  b {
    font-weight: bold;
  }
  span {
   padding-top: 0.3em;
    display: block;
  }
`

const FirstRow = styled(Row)`
  padding: 0 10px;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
  }
`

const SecondRow = styled(Row)`
  padding: 20px 10px 10px;
  @media (min-width: ${breakpoints.md}) {
    padding: 20px 0 10px;
  }
`

const Right = styled.div`
  text-align: right;
`

const RightText = styled.div`
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
`

const coolBoxKeyframes = keyframes`
  0% {
    background: ${colors.background};
  }
  100% {
    background: ${colors.accent3};
  }
`

const Icon = styled.div`
  background-color: ${colors.background};
  display: inline-block;
  padding: .25em .4em;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  cursor: pointer;
  &:hover {
    animation-name: ${coolBoxKeyframes};
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    background: ${colors.accent3};
  }
`

const FacebookIcon = styled.img`
  width: 20px;
  height: 20px;
  padding-top: 2px;
`


const Footer = () => (
  <FooterBlock>
    <Container>
      <FirstRow>
        <Col xs={6} sm={6}><IrieLogo src={LogoWhiteImg} alt={"Irie Technology Logo"}/></Col>
        <Col xs={6} sm={6}>
          <Right><Icon onClick={() => window.open("https://www.facebook.com/irietech.net", "_blank")}><FacebookIcon src={FacebookImg}/></Icon></Right>
        </Col>
      </FirstRow>
      <SecondRow>
        <Col xs={6} sm={6}>
          <b>IrieTech Sp. z o.o.</b>
          <br/>
          <span>
            Fryderyka Chopina 35/13,<br/>
            20-023 Lublin, Poland<br/>
          </span>
          <span>VAT ID: 7123406134</span>
        </Col>
        <Col xs={6} sm={6}>
          <RightText>@2023 IrieTech. All rights reserved</RightText>
        </Col>
      </SecondRow>
    </Container>
  </FooterBlock>
);

export default Footer;